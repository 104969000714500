.city-temps-card {
    width: 90%;
    text-align: left;
    margin: 15px auto;
    transition: 0.3s all linear;
}

.city-temps-card:hover {
    border-color: #0d6efd;
    cursor: pointer;
}