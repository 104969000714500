.search-form, .search-by-location-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1em 0;
    width: 100%;
}

.search-form p {
    margin: 0 auto 0 0;
    line-height: 50px;
}

.search-container {
    margin: 3em auto;
    width: 675px;
}

.search-form-button {
    border-left: none;
    border-radius: 0 5px 5px 0;
    transition: 0.2s all linear;
    height: 50px;
}

.search-button-control {
    border-left: none;
    border-radius: 5px;
    transition: 0.2s all linear;
    height: 50px;
}

.search-form-button:hover {
    cursor: pointer;
}

.search-form-input, .search-location-input {
    font-size: large;
    border-radius: 5px 0 0 5px;
    outline: none;
    border-right: none;
    height: 50px;
    width: 300px;
}

#search-location-longitude {
    border-radius: 0;
}

.dropdown-toggle {
    height: 50px;
}

.sort-results {
    margin-left: auto;
}

.location-desc {
    text-align: left;
}